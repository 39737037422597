// @flow
import React from 'react';
import styled from 'styled-components';
import { getGlossaryDefinition } from '../utils/glossary-helpers';
import UnstyledLink from './link';
import { large_screen } from '../utils/styles';

const Link = styled(UnstyledLink)`
  ${large_screen} {
    font-weight: bold;
  }
`;

export default class GlossaryQuote extends React.Component<{
  children: string,
  small?: boolean,
}> {
  render() {
    const { children, small } = this.props;

    const def = getGlossaryDefinition(children);

    if (!def) {
      return null;
    }

    const Container = (props: any) =>
      small ? <span {...props} /> : <blockquote {...props} />;

    return (
      <Container>
        <Link to={def.route}>{def.name}</Link>
        <br />
        {def.quoteDefinition}
      </Container>
    );
  }
}
