// @flow
import React, { type Node } from 'react';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import {
  MdPause,
  MdPlayArrow,
  MdSkipNext,
  MdSkipPrevious,
} from 'react-icons/md';
import type { ImageType } from '../utils/types';
import { border_radius } from '../utils/styles';

const Container = styled.figure`
  margin-bottom: 1.66667rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Caption = styled.figcaption`
  color: rgba(0, 0, 0, 0.5);
`;

const Image = styled(GatsbyImage)`
  ${border_radius};
`;

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 16px;
  border-radius: 28px;
  line-height: 12px;
  transition: background-color 300ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

type Props = {
  images: Array<ImageType>,
  captions: Array<Node>,
  interval?: number,
  style?: any,
  className?: string,
};

type State = {
  index: number,
  playing: boolean,
};

export default class Carousel extends React.Component<Props, State> {
  static defaultProps = {
    interval: 1000,
    captions: [],
  };

  timerId = undefined;

  state = {
    index: 0,
    playing: false,
  };

  componentDidMount() {
    this.timerId = setInterval(this.tick, this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  tick = () => {
    if (this.state.playing) {
      this.setState({ index: this.nextIndex() });
    }
  };

  prevIndex = () => {
    return (
      (this.state.index + this.props.images.length - 1) %
      this.props.images.length
    );
  };

  nextIndex = () => {
    return (this.state.index + 1) % this.props.images.length;
  };

  render() {
    const { images, captions, style, className } = this.props;
    const { index, playing } = this.state;

    const displayImages = images.map((img, i) => {
      return (
        <Image
          key={i}
          fluid={img.childImageSharp.fluid}
          style={{ display: i === index ? 'block' : 'none' }}
        />
      );
    });

    const caption = (
      <Caption>
        {`${index + 1}/${images.length}`}
        {captions[index] && ': '}
        {captions[index]}
      </Caption>
    );

    return (
      <Container style={style} className={className}>
        {displayImages}
        <ButtonContainer>
          <Button onClick={this.handlePreviousImageClick}>
            <MdSkipPrevious />
          </Button>
          <Button onClick={this.handlePlayPauseImageClick}>
            {playing ? <MdPause /> : <MdPlayArrow />}
          </Button>
          <Button onClick={this.handleNextImageClick}>
            <MdSkipNext />
          </Button>
        </ButtonContainer>
        {caption}
      </Container>
    );
  }

  handlePreviousImageClick = () => {
    this.setState({
      playing: false,
      index: this.prevIndex(),
    });
  };

  handlePlayPauseImageClick = () => {
    const playing = !this.state.playing;
    this.setState({ playing });
  };

  handleNextImageClick = () => {
    this.setState({
      playing: false,
      index: this.nextIndex(),
    });
  };
}
