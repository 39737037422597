// @flow
import React from 'react';
import Layout from '../components/layout';
import type { FrontMatter, ImageType } from '../utils/types';
import { withFrontMatter } from '../components/front-matter';
import GlossaryQuote from '../components/glossary-quote';
import { graphql } from 'gatsby';
import Carousel from '../components/carousel';
import Link from '../components/link';
import GlossaryLink from '../components/glossary-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
    _21: ImageType,
    _22: ImageType,
    _23: ImageType,
    _24: ImageType,
    _25: ImageType,
    _26: ImageType,
    _27: ImageType,
    _28: ImageType,
    _29: ImageType,
  },
};

class Canyoneering_PartnerCapture extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <GlossaryQuote>partner-capture</GlossaryQuote>
        <p>
          Most canyoneers do partner captures wrong. The correct way doesn't
          necessarily feel natural and requires strict discipline from both the
          capturers and capturee. After getting over that initial awkwardness
          and it starts to make sense partner captures go from guesswork,
          jumping, and occasional falls to a consistently safe and repeatable
          set of steps for efficiently moving down the canyon.
        </p>
        <p>
          In order to become proficient in partner captures practice outside of
          canyons is paramount. It took me years, many practice sessions, and
          several sets of instruction from the greatest down climb guide before
          it finally clicked. I hope it doesn't take as long for you.
        </p>
        <h2>Roles</h2>
        <p>
          The <b>capturee</b> is the canyoneer who is being lowered. The
          capturee actually does very little and must trust and rely on the
          capturers to get them down safely. This is the awkward part because
          the capturee has to completely surrender themselves to their capturer.
        </p>
        <p>
          The <b>capturer or capturers</b> are the canyoneers who are lowering
          the capturee.
        </p>
        <h2>Simple capture</h2>
        <ol>
          <li>
            <b>Capturer</b> is down below with arms up ready to capture.
          </li>
          <li>
            <b>Capturee</b> gets into position:
            <ul>
              <li>
                Their legs must be straight - bending them or trying to actively
                down climb is the biggest mistake a capturee can make
              </li>
              <li>Leans back against the rock to maximize friction</li>
              <li>
                Uses their arms to add friction as well by weighting hand holds
                or simply splay them out and press against the rock
              </li>
              <li>
                It's easier without packs but if it's a relatively
                straightforward capture then no need to take them off
              </li>
            </ul>
          </li>
          <li>
            <b>Capturer</b> holds the capturee's feet up while slowly lowering
            them, transitioning from holding their body weight to pressing their
            shins against the rock
          </li>
          <li>
            <b>Capturer</b> carefully lowers the capturee by leaning and
            pressing them against the rock, walking their hands up the
            capturee's legs
          </li>
          <p>Once the capturee is low enough...</p>
          <li>
            <b>Capturee</b> leans forward and places their hands the capturer's
            shoulders
          </li>
          <li>
            <b>Capturer</b> lowers them the rest of the way
          </li>
        </ol>
        <Carousel
          images={[
            data._1,
            data._2,
            data._3,
            data._4,
            data._5,
            data._6,
            data._7,
            data._8,
          ]}
          captions={[
            'Capturer has arms raised indicating that they are in position. Capturee moves into position',
            "Capturee and capturer are in position. Capturer is now controlling capturee's descent",
            'Slowly lowering...',
            'Slowly lowering...',
            'Slowly lowering while hands transition to shins',
            'Slowly lowering while hands transition to thighs',
            "Capturer stops lowering and capturee puts hands on capturer's shoulders",
            'Capturee controls the final piece of the lower and is down',
          ]}
        />
        <h2>Multiple capturers</h2>
        <ol>
          <li>
            If there are two capturers, each capturer takes a leg of the
            capturee. The capturee puts one hand on each inside shoulder of the
            capturer
          </li>
          <li>
            If there are three or four capturers, they should stand behind and
            stabilize the first two capturers
          </li>
          <li>
            If there are more than four they should have already gone ahead to
            the next obstacle
          </li>
        </ol>
        <h2>Pyramid capture</h2>
        <p>
          There are two types of pyramid captures. One of them, common in Utah
          County, is also known as <em>MLM recruiting</em>. The{' '}
          <em>canyoneering</em> pyramid capture builds on a standard partner
          capture. This time there are three levels of canyoneers: the
          top-level-capturee, middle-level-capturer, and bottom-level-capturers.
        </p>
        <p>
          The <b>top-level-capturee</b>'s job is the same as the capturee in a
          partner capture. The only difference is that they will be captured
          from a much higher distance.
        </p>
        <p>
          The <b>middle-level-capturer</b>'s job is to hold up the
          top-level-capturee while they are captured with their belly toward the
          rock. Their body will be captured completely. Once they are down they
          assist capturing the top-level-capturee.
        </p>
        <p>
          The <b>bottom-level-capturers</b> have a similar job to the capturer
          on a standard partner capture. The only difference is that instead of
          slowly lowering one person they will be slowly lowering two people
          stacked on top of each other.
        </p>
        <Carousel
          images={[
            data._9,
            data._10,
            data._11,
            data._12,
            data._13,
            data._14,
            data._15,
            data._16,
            data._17,
            data._18,
            data._19,
            data._20,
            data._21,
            data._22,
            data._23,
            data._24,
            data._25,
            data._26,
            data._27,
            data._28,
            data._29,
          ]}
          captions={[
            'Middle-level-capturer is getting into position',
            'Middle-level-capturer is getting into position',
            'Middle-level-capturer is getting into position',
            'Middle-level-capturer is in position',
            'Top-level-capturee gets into position',
            'Top-level-capturee is in position',
            'Bottom-level-capturers start capturing top-level-capturee',
            'Slowly lowering...',
            'Slowly lowering...',
            'Slowly lowering...',
            'Slowly lowering...',
            'Slowly lowering...',
            'Slowly lowering...',
            'Middle-level-capturer is now on ground',
            'Bottom-level-capturers can now reach top-level-capturee and help with a standard partner capture.',
            'Slowly lowering while hands transition to shins',
            'Slowly lowering while hands transition to thighs',
            'Slowly lowering...',
            "Wasn't that easy?",
            "Capturee leans down and puts hands on capturers' shoulders",
            'Capturee is down',
          ]}
        />
        <p>
          Why is the pyramid capturing in this order with the
          middle-level-capturer being lowered with the top level? Why not
          capture just the top level to the ground and then the middle level?
          The main reason is that the middle-level-capturer does not have the
          ability to push the top-level-capturee against the rock and therefore
          cannot preform an effective capture. And how do I know that? I was in
          a group that tried it this way once and the top and middle levels both
          came crashing down. I made that mistake for you and me so you don't
          have to.
        </p>
        <h2>Overhanging Partner Captures</h2>
        <p>
          Partner captures rely on pressing the capturee against the rock to
          provide friction for easier lowering. Consequently partner captures
          using this technique do not work when the drop is{' '}
          <GlossaryLink>overhanging</GlossaryLink>.
        </p>
        <p>
          It is possible to generalize this technique to allow for overhanging
          captures, but I have no experience with it and can only refer you to a{' '}
          <GlossaryLink>Canyon Collective</GlossaryLink> forum thread where Ram
          describes a technique called{' '}
          <Link to="http://canyoncollective.com/threads/partner-capture-from-overhanging-ledges.25360/#post-111268">
            The Circle of Love
          </Link>
          :
        </p>
        <blockquote>
          <p>
            The geometry of each drop varies, so one size does NOT fit all. But
            here are some guidelines.
          </p>
          <ul>
            <li>
              Captures involving overhangs have their limits a few feet lower
              than vertical or low angle captures
            </li>
            <li>
              The same support that creates the feeling that the capturers are
              standing on flat ground applies. Support of the capturers is
              perhaps even more critical.
            </li>
            <li>
              If the overhang goes more than 7 feet up from the ground, the
              exercise becomes much more difficult
            </li>
            <li>
              Strength of capturers and lightness of capturee needs a bit more
              safety margin
            </li>
            <li>
              pack drags to help "over the lips" that are difficult, is a good
              additional tool
            </li>
          </ul>
          <p>
            The capture method most used for overhangs is called{' '}
            <b>The Circle of Love</b>.
          </p>
          <p>
            One of the capturers creates a round circle with both arms, up as
            high as they can, with balance and strength. Others supporting this
            person must make him (usually tall strong males) as stable as
            possible. The person being captured slips over the edge, facing in
            (most common), on their side (often) or facing out (least used),
            aims their legs into the arm circle, created by the capturer.
            Critical that the capturee have their legs together and mostly
            straight. As the legs slide into and below the arm circle, the
            capturer, closes the circle, but still maintains the circle shape.
            As the legs get lower, the circle closes to around the thighs and
            squeezes the legs of the capturee. This must not be done too high
            off the ground or too low on the capturee's body. At least the
            highs, not lower.
          </p>
          <p>
            That is the basic mechanical part, but there is more needed. A
            second capturer is needed every time in the "Circle" maneuver. They
            guide the legs down in and stabilize the the other capturer. Others
            can help each of the capturers be strong and stable, holding feet,
            pushing into backs and in many ways depending on the circumstances.
            They can also slow the descent and control the capturee, once they
            et low enough.
          </p>
          <p>
            The capturee often will bend at the waist just enough to use the
            shoulders of the capturer, supporting the other capturer doing the
            circle. The capturer doing the circle, can bend their knees when the
            circle is squeezed around the thighs of the capturee, and with the
            help of others bring the capturee to the ground. When done
            correctly, the circle capturerer will have their cheek, plush
            against the hip of the person being captured. That is where the
            origin of the name comes from.
          </p>
          <p>R</p>
        </blockquote>
      </Layout>
    );
  }
}

export default withFrontMatter('partner-capture')(Canyoneering_PartnerCapture);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-10.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-11.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-12.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-13.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-14.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-15.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-16.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-17.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _18: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-18.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _19: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-19.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _20: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-20.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _21: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-21.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _22: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-22.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _23: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-23.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _24: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-24.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _25: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-25.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _26: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-26.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _27: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-27.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _28: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-28.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _29: file(
      relativePath: {
        eq: "images/canyoneering/partner-capture/Partner_Capture-29.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
